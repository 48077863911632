<template>
  <div v-if="returnParcel">
    <portal to="header">
      <terminal-header
        :title="$t('Return Parcel') + ': ' + returnParcel.id"
        @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>

    <q-carousel
      v-model="slide"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      class="terminal-min-height"
    >
      <q-carousel-slide name="returnedItems">
        <div class="fit q-py-sm">
          <div v-if="order">
            <div
              v-for="item in order.items.returned"
              :key="item.id"
            >
              <order-item :item="item" />
            </div>
          </div>

          <portal to="bottom-message">
            <div class="picking-hints text-center q-mb-md">
              {{ $t('Returned items') }}
            </div>
          </portal>
        </div>
      </q-carousel-slide>

      <q-carousel-slide name="scan">
        <div class="fit q-py-sm">
          <jumbotron v-if="!place" />

          <div v-if="item">
            <order-item :item="item" />
          </div>

          <div v-if="place">
            <place-object
              :data="place"
              :type="$t(place.type[0].toUpperCase() + place.type.slice(1)) + ' ' + $t('place')"
            />
          </div>

          <picking-message :data="message" />
        </div>
      </q-carousel-slide>

      <q-carousel-slide name="notReturnedItems">
        <div class="relative fit q-py-sm">
          <div v-if="order">
            <div
              v-for="item in order.items.notReturned"
              :key="item.id"
            >
              <order-item :item="item" />
            </div>
          </div>

          <portal to="bottom-message">
            <div class="picking-hints text-center q-mb-md">
              {{ $t('Not returned items') }}
            </div>
          </portal>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'ReturnParcelProcess',
  data () {
    return {
      tip: 'Scan working (universal) place',
      slide: 'scan',
      action: 'scanPlace',
      place: null,
      order: null,
      item: null,
      returnParcel: null
    }
  },
  computed: {
    message () {
      return {
        position: 'bottom',
        text: this.tip
      }
    },
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Confirmed'),
          icon: 'check',
          value: true,
          onChanged: () => {
            this.confirmed()
          }
        },
        {
          id: 2,
          label: this.$t('Return'),
          icon: 'reply',
          disable: !this.order,
          value: true,
          onChanged: () => {
            this.returnOrder()
          }
        }
      ]
    }
  },
  mounted () {
    this.$service.returnParcel.find(this.$route.params.id)
      .then(item => {
        this.returnParcel = item
      })
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    ...mapActions([
      'saveOrderByPayload',
      'saveDeliveryRequestByPayload'
    ]),
    scanPlace (barcode) {
      const query = {
        action: 'get-disassemble-place',
        criteria: {
          place: barcode.value
        }
      }

      return this.$service.storageItem.getAll(query)
        .then(data => {
          return this.$service.storagePlace.get(barcode.value)
        })
        .then(data => {
          this.place = data
          this.action = 'scanOrder'
          this.tip = 'Scan order'
        })
    },
    scanOrder (barcode) {
      const query = {
        action: 'get-order-items-by-tracking-number',
        criteria: {
          trackingNumber: barcode.value,
          returnDocId: this.returnParcel.id
        }
      }

      return this.$service.storageItem.getAll(query)
        .then(data => {
          if (data.items) {
            this.order = data
            this.action = 'scanItem'
            this.tip = 'Scan item'
            return
          }

          this.addErrorNotification(data.message.message)
        })
    },
    scanItem (barcode) {},
    mutateAction (barcode) {},
    handleBarcode (barcode) {
      this.slide = 'scan'
      this.mutateAction(barcode)

      this[this.action](barcode)
    },
    confirmed () {
      this.$service.wholesaleConsumption.save({ state: 'complete' }, this.returnParcel.id)
    },
    returnOrder () {
      const data = this.order.items.notReturned.reduce((acc, item) => {
        const items = item.item_ids.map(id => ({ id, state: 'missing' }))
        return [...acc, ...items]
      }, [])

      return this.$service.storageItemEntity.patch(data)
        .then(_ => {
          return this.$service.order.save({ state: 'partly_return' }, this.order.order.orderId)
        })
        .then(_ => {
          return this.$service.deliveryRequest.save({ state: 'returned_partly' }, this.order.order.deliveryRequestId)
        })
        .then(_ => {
          this.order = null
          this.place = null
        })
    }
  }
}
</script>
